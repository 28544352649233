import { InfoCircleOutlined } from '@ant-design/icons';
import { Radio, Button, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type Store from '../../store';
import styles from './index.less';

export const StepOne: React.FC<{
  store: Store;
}> = observer((props) => {
  const { serveType, onServeTypeChange, stepGo, whetherSupport } = props.store;
  return (
    <div className={styles.stepOne}>
      <div className={styles.option}>
        <label>
          售后服务类型：
        </label>
        <div className={styles.optionArea}>
          <Radio.Group
            onChange={(e) => {
              const value = e.target.value;
              onServeTypeChange(value);
            }}
            value={serveType}
          >
            <Space
              direction="vertical"
              size={0}
            >
              <Radio
                disabled={!whetherSupport}
                value={1}
              >
                <div className={styles.normalTips}>
                  <span>
                    常规售后
                  </span>
                  {!whetherSupport && (
                    <span className={styles.tips}>
                      <InfoCircleOutlined style={{
                        color: '#FAA131',
                        marginRight: '4px',
                      }}
                      />
                      <span>
                        无商品支持常规售后，如有质量问题请走特殊申请
                      </span>
                    </span>
                  )}
                </div>
                <div className={styles.radioDesc}>
                  根据商品所属退货服务政策，申请正常的售后服务
                </div>
              </Radio>
              <Radio value={2}>
                特殊申请
                <div className={styles.radioDesc}>
                  商品存在质量问题等情况，申请特殊处理
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      </div>
      <div className={styles.desc}>
        <header className={styles.header}>
          退货说明：
        </header>
        <div>
          <p>
            1、未按照提示地址寄回，由客户自行联系快递重新寄出；造成快递丢失或者已无破损，不予以退款；
          </p>
          <p>
            2、客户改标商退货商品，到付寄回給改标对象，待对方将费用结算给平台后，退款给寄件人，若寄件人是改标人，不予退款，到付寄回商品；
          </p>
          <p>
            3、擅自寄回未在APP上申请退货的商品，不予退款，退货商品将到付寄回给寄件人
          </p>
          <p>
            4、关于非无理由全退的商品寄回邮费建议：若是次品寄件人先垫付，平台申请退款；若是其它原因退回商品，统一由寄件人自理
          </p>
          <p>
            5、温馨提示：客服同意退货后，请在5天内，寄回快递，否则申请会自动关闭，若在售后期，可重新申请。
          </p>
          <p>
            6、寄回时未填写单号，将不会为您操作退款，售后会自动关闭，仓库无法处理，需重新联系客服并提供退货物流订单；因超时未填写快递单号而被关闭的退货商品不予退款，退货商品将到付寄回给店主。
          </p>
          <p>
            7、寄件要求：请将商品、吊牌、包装完整寄回
          </p>
        </div>
      </div>
      <div className={styles.btnLine}>
        <Button
          onClick={() => stepGo(1)}
          type="primary"
        >
          下一步
        </Button>
      </div>
    </div>
  );
});
