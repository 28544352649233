import { Spin, Steps } from 'antd';
import { FullModal } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { Result, StepOne, StepThree, StepTwo } from './component';
import { steps } from './constant';
import styles from './index.less';
import type Store from './store';

const { Step } = Steps;

// 批量申请售后
const ApplyAfterSales: React.FC<{ store: Store; }> = observer((props) => {
  const stepsContent = {
    '0': <StepOne store={props.store}/>,
    '1': <StepTwo store={props.store}/>,
    '2': <StepThree store={props.store}/>,
  };

  const { step, stepGo, failReason, result, fullModalVisible, changeVisible, jumpToAfterSaleOrder, pageLoading } = props.store;
  return (
    <FullModal
      onCancel={() => changeVisible(false)}
      title="申请售后"
      visible={fullModalVisible}
    >
      <Spin spinning={pageLoading}>
        <div className={styles.page}>
          {step !== 3 && (
            <>
              <header className={styles.header}>
                <Steps current={step}>
                  {steps.map((el) => {
                    return (
                      <Step
                        key={el.status}
                        title={el.title}
                      />
                    );
                  })}
                </Steps>
              </header>
              <div className={styles.content}>
                {stepsContent[step]}
              </div>
            </>
          )}
          {
            step === 3 && (
              <Result
                again={stepGo}
                backManage={jumpToAfterSaleOrder}
                failReason={failReason}
                result={result}
              />
            )
          }
        </div>
      </Spin>
    </FullModal>
  );
});

export default ApplyAfterSales;
