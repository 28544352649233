import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import { api } from '../api';
import type { ICourierInfo } from '../interface';
import type { Store } from '../store';

export class CourierInfoStore {
  constructor(options) {
    this.parent = options.parent;
  }

  public parent: Store;

  @observable public visible = false;// 是否展示物流信息

  @observable public loading = false;// 抽屉loading

  @observable public tabKey = '1';

  public courierInfoList: ICourierInfo[] = [];

  @action public onTabChange = (tab: string) => {
    this.tabKey = tab;
  };

  // 关闭抽屉
  @action public onDrawerClose = (): void => {
    Object.assign(this, {
      courierName: '',
      courierOrderNo: '',
      returnSendTime: '',
      totalNum: null,
      skuVoList: [],
      courierInfoList: [],
      visible: false,
      mallReturnOrderId: null,
    });
  };

  // 打开抽屉
  @action public onDrawerOpen = (mallSaleOrderId: number) => {
    this.visible = true;
    this.loading = true;
    request<BaseData<ICourierInfo[]>>({
      url: api.getCourierInfo,
      method: 'GET',
      params: { mallSaleOrderId },
    }).then((res) => {
      this.courierInfoList = res.data;
      this.courierInfoList.forEach((item) => {
        item.detailVoList.forEach((item) => {
          item.picUrl = item.pic;
          item.goodsSkuId = item.skuId;
        });
      });

      // const { courierName, courierOrderNo, returnSendTime, totalNum, skuVoList, courierInfoList } = res.data;
      // Object.assign(this, {
      //   courierName,
      //   courierOrderNo,
      //   returnSendTime,
      //   totalNum,
      //   skuVoList,
      //   courierInfoList,
      // });
    })
      .finally(() => {
        this.loading = false;
      });
  };
}
