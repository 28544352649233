import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { GoodsVoList } from './interface';
import type Store from './store';

interface Props {
  store: Store;
}

interface GoodsProps {
  item: GoodsVoList;
}

const ReturnGoods = observer((props: Partial<Props>): JSX.Element => {
  const {
    orderType,
    returnDeadLineTime,
    onExamineClick,
    mallSaleOrderGoodsNum,
    visible,
    loading,
    onDrawerClose,
    desc,
    mallReturnRightsNo,
    returnSkuNo,
    returnTotalAmount,
    goodsList,
  } = props.store;
  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      closeIcon={null}
      mask

      // maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      onClose={onDrawerClose}
      open={visible}
      width={400}
    >
      <div className={styles.drawerHeader}>
        <span>
          无忧退货
        </span>
        <CloseOutlined
          className={styles.closeIcon}
          onClick={onDrawerClose}
        />
      </div>
      <Spin
        spinning={loading}
        tip="加载中..."
        wrapperClassName={styles.spin}
      >
        <div className={styles.drawerContent}>
          {orderType === 1 ? (
            <div className={styles.top}>
              <div className={styles.total}>
                <span className={styles.mr16}>
                  {`本单无忧退货：${mallSaleOrderGoodsNum ?? '-'}件`}
                </span>
                服务费：
                <span className={styles.redColor}>
                  {`¥${returnTotalAmount.toFixed(2) ?? '-'}`}
                </span>
              </div>
              <div className={styles.time}>
                {returnDeadLineTime || '-'}
                &nbsp;前可享受无理由退货
              </div>
            </div>
          ) : (
            <div className={styles.wholesale}>
              <div>
                无忧退货单号：
                <span>
                  {mallReturnRightsNo || '-'}
                </span>

                {returnSkuNo >= 1 ? (
                  <Button
                    className={styles.examine}
                    onClick={onExamineClick}
                    type="link"
                  >
                    查看关联订单
                  </Button>
                ) : ''}
              </div>
              <span>
                {returnSkuNo || '-'}
                个订单，
                {mallSaleOrderGoodsNum || '-'}
                件商品可以无忧退货，服务费为：
                <span className={styles.returnTotalAmount}>
                  {returnTotalAmount?.toFixed(2) || '-'}
                </span>
              </span>
            </div>
          )}
          <div>
            {goodsList.map((el: GoodsVoList) => {
              return (
                <GoodsCard
                  item={el}
                  key={el.goodsId}
                />
              );
            })}
          </div>
        </div>
      </Spin>
    </Drawer>
  );
});

const GoodsCard = (props: GoodsProps): JSX.Element => {
  const {
    shopName,
    sellerOuterNo,
    goodsName,
    mainPicUrl,
    returnTotalNum,
    returnedNum,
  } = props.item;
  return (
    <div
      className={styles.goodsCard}
    >
      <div className={styles.flex}>
        <img
          className={styles.goodsImg}
          src={mainPicUrl ?? ''}
        />
      </div>
      <div className={`${styles.lh16} ${styles.color6D} ${styles.ml8}`}>
        <div className={`${styles.ellipsis} ${styles.mt2} ${styles.color2B}`}>
          {goodsName}
        </div>
        <div className={styles.mt2}>
          {`货号：${sellerOuterNo}`}
        </div>
        <div className={styles.mt2}>
          {`档口：${shopName}`}
        </div>
        <div>
          <span className={styles.mr12}>
            {`无忧退${returnTotalNum}件`}
          </span>
          <span>
            {`已退${returnedNum}件`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReturnGoods;
