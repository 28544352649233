import { Button, Space } from 'antd';
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../../utils/common';
import styles from './index.less';

export const Result: React.FC<Partial<
{
  result: 'success' | 'fail';
  failReason: string;
  backManage: () => void;
  again: (go: 1 | -1) => void;
}>> = (props) => {
  const { result, failReason, again, backManage } = props;
  return (
    <div className={styles.result}>
      {result === 'success' && (
        <div className={styles.content}>
          <img
            className={styles.statusIcon}
            src={`${PUBLIC_IMAGE_URL}successStatus.png`}
          />
          <div className={styles.resultName}>
            提交成功
          </div>
          <div className={styles.btnLine}>
            <Space>
              <Button onClick={backManage}>
                返回售后管理
              </Button>
            </Space>
          </div>
        </div>
      )}
      {result === 'fail' && (
        <div className={styles.content}>
          <img
            className={styles.statusIcon}
            src={`${PUBLIC_IMAGE_URL}failStatus.png`}
          />
          <div className={styles.resultName}>
            提交失败
          </div>
          <div className={styles.resultReason}>
            {`失败原因：${failReason || ''}`}
          </div>
          <div
            className={styles.btnLine}
            onClick={() => again(-1)}
          >
            <Button type="primary">
              重试
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
