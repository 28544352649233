import { Space, Button, Table } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type Store from '../../store';
import styles from './index.less';

const serveTypeMap = {
  '1': '常规售后',
  '2': '特殊申请',
};

export const StepTwo: React.FC<{ store: Store; }> = observer((props) => {
  const { tableProps, stepGo, returnAmount, serveType, serveGoodsList, rowSelection, choosedNum, deliverType, changeDeliveryType, deliveredList, toDeliverList, tableLoading } = props.store;
  return (
    <div className={styles.stepTwo}>
      <div className={styles.typeAndAmount}>
        <label>
          售后服务类型：
        </label>
        <span>
          {serveTypeMap[serveType]}
        </span>
      </div>
      <div className={styles.typeAndAmount}>
        <label>
          预估退款金额：
        </label>
        <span className={styles.amount}>
          {`¥${returnAmount}`}
        </span>
      </div>
      <div className={styles.operationBtn}>
        <Space>
          <div
            className={`${styles.deliverType} ${deliverType === 'delivered' ? styles.delivered : ''}`}
            onClick={() => changeDeliveryType('delivered')}
            style={{ display: deliveredList?.length ? 'flex' : 'none' }}
          >
            已发货
          </div>
          <div
            className={`${styles.deliverType} ${deliverType === 'toDeliver' ? styles.delivered : ''}`}
            onClick={() => changeDeliveryType('toDeliver')}
            style={{ display: toDeliverList?.length ? 'flex' : 'none' }}
          >
            未发货
          </div>
        </Space>
      </div>
      <div className={styles.tableContainer}>
        <Table
          {...tableProps}
          bordered
          dataSource={serveGoodsList}
          loading={tableLoading}
          pagination={false}
          rowSelection={rowSelection}
          scroll={{ y: '45vh' }}
          size="small"
        />
        <div className={styles.choosed}>
          已选
          <span style={{ color: '#1978FF' }}>
            {choosedNum}
          </span>
          件
        </div>
      </div>
      <div className={styles.btnLine}>
        <Space>
          <Button
            onClick={() => stepGo(1)}
            type="primary"
          >
            下一步
          </Button>
          <Button onClick={() => stepGo(-1)}>
            上一步
          </Button>
        </Space>
      </div>
    </div>
  );
});
