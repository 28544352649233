import { observable, action } from 'mobx';
import type { BaseData } from 'egenie-utils';
import { Modal } from 'antd';
import { request } from 'egenie-utils';
import type { WaitingPaybatchData } from '../interface';

export class PayByBatchStore {
  constructor(options) {
    this.parent = options.parent;
  }

  public parent;

  @observable public loading = false;

  @observable public visible = false;

  @observable public waitForPayBatchData = [];

  @action public togglePayBatchModal = (visible: boolean) => {
    if (visible) {
      this.loading = true;
      this.getWaitForBatchData();
    }
    this.visible = visible;
  };

  // 刷新付款单批次列表
  public getWaitForBatchData = () => {
    this.loading = true;
    return request<BaseData<WaitingPaybatchData[]>>({ url: '/api/mall/rest/saleOrder/queryWaitingPayBatchData' }).then((res) => {
      this.waitForPayBatchData = res.data;
    })
      .finally(() => {
        this.loading = false;
      });
  };

  // 处理付款
  public handleEachPay = (callBackUrl, orderNum, outBatchNo) => {
    console.log(`/egenie-ts-vogue/cashier/index?cashierCode=one-piece-consignment-cashier&outBatchNo=${outBatchNo}&orderNum=${orderNum}&callBackUrl=${callBackUrl}&noRealName=1`);
    window.top.egenie.openTab(`/egenie-ts-vogue/cashier/index?cashierCode=one-piece-consignment-cashier&outBatchNo=${outBatchNo}&orderNum=${orderNum}&callBackUrl=${callBackUrl}&noRealName=1`, 'cashier', '收银台');
    Modal.confirm({
      title: '请您在新开单的页面上完成付款',
      content: '付款完成前请不要关闭窗口，完成付款后请根据您的情况点击下面的按钮',
      okText: '已完成付款',
      onCancel: this.getWaitForBatchData,
      onOk: this.getWaitForBatchData,
    });
  };
}
