import React from 'react';
import { Alert, Button, Modal, Spin } from 'antd';
import type { CancelByBatchStore } from './store';
import { observer } from 'mobx-react';
import styles from './index.less';
import { PUBLIC_IMAGE_URL } from '../../../utils';

export const CancelByBatchModal: React.FC<{ store: CancelByBatchStore; }> = observer((props) => {
  const { visible, togglePayBatchModal, waitForPayBatchData, loading, handleEachCancel } = props.store;
  return (
    <Modal
      bodyStyle={{ padding: '12px 24px 0' }}
      footer={(
        <Button onClick={() => {
          togglePayBatchModal(false);
        }}
        >
          取消
        </Button>
      )}
      maskClosable={false}
      onCancel={() => {
        togglePayBatchModal(false);
      }}
      open={visible}
      title="批量取消（按下单批次）"
      wrapClassName={styles.modalWrapper}
    >
      <Spin spinning={loading}>
        {
          Array.isArray(waitForPayBatchData) && waitForPayBatchData.length > 0 ? (
            <>
              <div className={styles.title}>
                待付款订单分
                <span className={styles.blueText}>
                  {waitForPayBatchData.length}
                </span>
                次下单，需要按批次取消
              </div>
              <Alert
                banner
                message="一次取消付款订单数较多，取消后请耐心等待5-10分钟后查看订单"
                showIcon
                style={{
                  height: '24px',
                  marginBottom: '5px',
                }}
                type="warning"
              />
              <div className={styles.payBatchWrapper}>
                {waitForPayBatchData.map((item, index) => (
                  <EachCancelBatch
                    amount={item.totalAmount}
                    callBackUrl={item.callBackUrl}
                    createTime={item.createTime}
                    handleCancel={handleEachCancel}
                    index={index + 1}
                    key={item.mallSaleOrderBatchId}
                    mallSaleOrderBatchNo={item.mallSaleOrderBatchId}
                    orderNum={item.orderNum}
                  />
                ))}
              </div>
            </>
          ) : <EmptyBatch/>
        }
      </Spin>
    </Modal>
  );
});

const EmptyBatch = () => {
  return (
    <div className={styles.emptyData}>
      <img src={`${PUBLIC_IMAGE_URL}emptyData.png`}/>
      <div>
        暂无待付款批次
      </div>
    </div>
  );
};

interface EachPayBatchProps{
  index: number;
  amount: number;
  createTime: string;
  orderNum: number;
  mallSaleOrderBatchNo: string;
  callBackUrl: string;
  handleCancel: (outBatchNo) => void;
}

const EachCancelBatch: React.FC<EachPayBatchProps> = (props) => {
  const { index, amount, createTime, orderNum, handleCancel, callBackUrl, mallSaleOrderBatchNo } = props;
  return (
    <div className={styles.eachPayBatch}>
      <div className={styles.index}>
        {index < 10 ? `0${index}` : index}
      </div>
      <div>
        <div className={styles.totalAmount}>
          应付总金额：
          <span className={styles.redText}>
            {amount.toFixed(2)}
          </span>
        </div>
        <div>
          下单时间：
          {createTime}
        </div>
      </div>
      <div className={styles.orderNum}>
        订单数：
        {orderNum}
      </div>
      <Button
        onClick={() => {
          handleCancel(mallSaleOrderBatchNo);
        }}
        type="primary"
      >
        取消
      </Button>
    </div>
  );
};
