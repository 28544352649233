import { request } from 'egenie-common';
import type { BaseData } from 'egenie-common';
import { observable, action } from 'mobx';
import type { Store } from '../store';
import type { GoodsVoList, ReturnGoodsList } from './interface';

export default class ReturnGoodsStore {
  constructor(props: Store) {
    this.parentStore = props;
  }

  @observable public parentStore: Store;

  @observable public visible = false;

  @observable public loading = false;

  @observable public orderType: number;

  @observable public returnTotalAmount: number; // 退货总价

  @observable public returnDeadLineTime: string; // 退货截止日期

  @observable public returnSkuNo: number; // 退货总件数

  @observable public mallReturnRightsNo: number; // 无忧退货单号

  @observable public mallSaleOrderGoodsNum: number; // 关联订单下商品数

  @observable public desc = ''; // 描述

  @observable public goodsList: GoodsVoList[] | [] = []; // 退货列表

  @action public onDrawerShow = (orderId: number | string, orderType: number): void => {
    this.initData();
    this.visible = true;
    this.orderType = orderType;
    this.queryGoodsList(orderId);
  };

  @action public onDrawerClose = (): void => {
    this.visible = false;
    this.initData();
  };

  @action public queryGoodsList = async(orderId: string | number): Promise<void> => {
    this.loading = true;
    const res: BaseData<ReturnGoodsList> = await request({ url: `/api/mall/rest/saleOrder/findReturnRightDetailInfo?mallSaleOrderId=${orderId}` });
    const { returnDeadLineTime, mallReturnRightsNo, mallSaleOrderGoodsNum, mallSaleOrderNum, returnServicePrice, goodsVoList } = res.data;
    Object.assign(this, {
      returnSkuNo: mallSaleOrderNum,
      mallSaleOrderGoodsNum,
      returnTotalAmount: returnServicePrice,
      goodsList: goodsVoList,
      mallReturnRightsNo,
      returnDeadLineTime,
    });
    this.loading = false;
  };

  @action public initData = (): void => {
    this.goodsList = [];
    this.returnTotalAmount = 0;
    this.mallSaleOrderGoodsNum = 0;
    this.returnDeadLineTime = undefined;
    this.mallReturnRightsNo = undefined;

    this.returnSkuNo = 0;
    this.loading = false;
  };

  @action public onExamineClick = () => {
    this.parentStore.programme.filterItems.updateFilterItem([
      {
        type: 'input',
        field: 'mallReturnOrderNo',
        value: `${this.mallReturnRightsNo}`,
      },
    ]);
    this.onDrawerClose();

    this.parentStore.queryOrder();
  };
}
