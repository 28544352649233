import { InfoCircleOutlined } from '@ant-design/icons';
import type { FilterItemOptions } from 'egenie-utils';
import { ImgFormatter } from 'egenie-utils';
import React from 'react';
import noPic from '../../assets/images/noPic.png';
import { cashierQueryResult } from '../cashier/widget/cashierQueryResult/index';
import { CopyIcon } from '../components';
import type { EachColumnProps, EachHeaderProps } from '../components/orderGrid/store';
import styles from './index.less';
import type { Store } from './store';

// 查询项
export const FILTER_ITEMS = (context: Store): FilterItemOptions[] => {
  return [
    {
      type: 'input',
      field: 'mallSaleOrderNo',
      label: '订单编号',
      isMultipleSearch: true,
    },
    {
      type: 'select',
      field: 'orderStatus',
      label: '订单状态',
    },
    {
      type: 'input',
      field: 'goodsName',
      label: '商品名称',
    },
    {
      type: 'input',
      field: 'goodsId',
      label: '商品ID',
    },
    {
      type: 'input',
      field: 'courierOrderNo',
      label: '快递单号',
    },
    {
      type: 'select',
      field: 'deliveryType',
      label: '提货方式',
      data: deliveryOptions,
    },
    {
      type: 'dateRange',
      field: 'time',
      label: '创建时间',
    },
    {
      type: 'select',
      field: 'userId',
      label: '下单账号',
    },
    {
      type: 'input',
      field: 'erpSaleOrderNo',
      label: 'ERP订单编号',
      isMultipleSearch: true,
    },
    {
      type: 'input',
      field: 'mallReturnOrderNo',
      label: '无忧退货编号',
    },
    {
      type: 'select',
      field: 'orderType',
      label: '订单类型',
      data: orderTypeOptions,
    },
    {
      type: 'input',
      field: 'platformOrderNo',
      label: '店铺订单编号',
      isMultipleSearch: true,
    },
    {
      type: 'select',
      field: 'expressDeliveryStatus',
      label: '快递停发',
      data: [
        {
          label: '是',
          value: '0',
        },
        {
          label: '否',
          value: '1',
        },
      ],
    },
  ];
};

// 订单类型
const orderTypeOptions = [
  {
    label: '手工下单',
    value: '1',
  },
  {
    label: '代发订单',
    value: '2',
  },
  {
    label: '衫数样衣订单',
    value: '3',
  },
  {
    label: '第三方来源订单',
    value: '4',
  },
  {
    label: '导入订单',
    value: '5',
  },
  {
    label: '国际订单',
    value: '8',
  },
];

// 提货方式
const deliveryOptions = [
  {
    label: '物流配送',
    value: '0',
  },
  {
    label: '档口自提',
    value: '1',
  },
];

// 订单表格列信息
export const GRID_COLUMNS = (context: Store): EachColumnProps[] => {
  return [
    {
      width: 20,
      name: '商品信息',
      merge: false,
      detailFormatter: (detail, row) => {
        const {
          skuPic,
          goodsName,
          sellerOuterNo,
          goodsSkuNo,
        } = detail;
        return (
          <div className={styles.productInfo}>
            {
              skuPic ? (
                <ImgFormatter
                  height={56}
                  value={skuPic}
                  width={56}
                />
              ) : (
                <img
                  className={styles.imgStyle}
                  src={noPic}
                />
              )
            }
            <div className={styles.productText}>
              <div
                className={styles.productName}
                onClick={() => {
                  if (context.associatedShopId) {
                    window.open(`/egenie-ts-vogue/distributor/goodsDetail/index?goodsId=${detail.goodsId}`);
                  } else {
                    window.open(`/egenie-ts-vogue/goodsDetail/index?goodsId=${detail.goodsId}`);
                  }
                }}
              >
                {goodsName || ' '}
                <i className="icon-sq"/>
              </div>
              <div>
                货号:
                {sellerOuterNo}
              </div>
              <div>
                SKU编码:
                {goodsSkuNo}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      width: 8,
      name: '规格',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div>
            {detail.goodsSpec}
          </div>
        );
      },
    },
    {
      width: 8,
      name: '单价(元)',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div>
            ¥
            {detail.price}
          </div>
        );
      },
    },
    {
      width: 8,
      name: '实付单价(元)',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div>
            ¥
            {detail.actualPrice}
          </div>
        );
      },
    },
    {
      width: 8,
      name: '采购数量',
      merge: false,
      detailFormatter: (row) => {
        return (
          <div>
            ×
            {row.originNum}
          </div>
        );
      },
    },
    {
      width: 8,
      name: '实发数量',
      merge: false,
      detailFormatter: (row) => {
        return (
          <div>
            ×
            {row.num}
          </div>
        );
      },
    },
    {
      width: 114,
      name: '售后状态',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div>
            <div>
              {row.returnOrderStatusDesc}
            </div>
            <div>
              {row.returnOrderIds && (
                <a
                  onClick={() => {
                    context.goToAfterSaleDetail(row.mallSaleOrderNo);
                  }}
                >
                  售后详情
                </a>
              )}
            </div>

          </div>
        );
      },
    },
    {
      width: 114,
      name: '实付款',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div>
            ¥
            {row.allAmount}
            <div className={styles.postAmount}>
              (运费：
              {row.postAmount}
              )
            </div>
            <div>
              {row.posPayTypeName}
            </div>
            {row.addServiceFlag === 1 && (
              <div className={styles.addServiceFlag}>
                <div className={styles.text}>
                  增值服务
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      width: 114,
      name: '提货方式',
      merge: true,
      detailFormatter: (row) => {
        return (
          <>
            <div>
              {row.deliveryTypeStr}
            </div>
            <div>
              {row.deliveryModeName}
            </div>
            {
              row.orderStatus !== 1 && row.deliveryType === 0 && row.haveCourierInfo && (
                <a
                  onClick={() => {
                    context.courierInfoStore.onDrawerOpen(row.mallSaleOrderId);
                  }}
                  type="link"
                >
                  查看物流
                </a>
              )
            }
            {row.expressDeliveryStatus === 0 && row.orderStatus === 1 ? (
              <a
                className={styles.courierStop}
                onClick={() => {
                  context.expressDeliveryPop();
                }}
              >
                <InfoCircleOutlined className={styles.courierStopIcon}/>
                快递停发
              </a>
            ) : null}
          </>
        );
      },
    },
    {
      width: 114,
      name: '订单状态',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div>
            {row.orderStatusName}
          </div>
        );
      },
    },
    {
      width: 114,
      name: '操作',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div>
            {/* 待付款和备货中 */}
            {row.orderStatus === 0 && (
              <a
                className={styles.operate}
                onClick={() => {
                  context.cancelOrder(row.mallSaleOrderId);
                }}
              >
                取消订单
              </a>
            )}
            {(row.returnable && row.orderType !== 6) && (
              <a
                className={styles.operate}
                onClick={() => {
                  context.applyAfterSaleStore.changeVisible(true, row.mallSaleOrderId);
                }}
              >
                申请售后
              </a>
            )}
            <a
              className={styles.operate}
              onClick={() => {
                window.top.egenie.openTab(`/egenie-ts-vogue/myOrderDetail/index?mallSaleOrderId=${row.mallSaleOrderId}`, 'myOrderDetail', '订单详情');
              }}
            >
              订单详情
            </a>
            {/* 待收货 */}
            {row.orderStatus === 2 && (
              <a
                className={styles.operate}
                onClick={() => {
                  context.confirmReceiveGoods(row.mallSaleOrderId);
                }}
              >
                确认收货
              </a>
            )}
            {/* 待付款需要付款(批量代发订单不支持单笔付款) */}
            {
              (row.orderStatus === 0 && !row.doesBatchOrder) && (
                <a
                  className={styles.operate}
                  onClick={() => {
                    const result = row.courierOrderNo ? [
                      row.businessId,
                      row.courierOrderNo,
                    ] : [row.businessId];
                    top.egenie.openTab(`/egenie-ts-vogue/cashier/index?tradeOrderIds=${JSON.stringify(result)}`, 'cashier', '收银台');
                    cashierQueryResult(context.queryOrder, context.queryOrder);
                  }}
                >
                  付款
                </a>
              )
            }
            {/* 再次购买 */}
            <a
              className={styles.operate}
              onClick={() => {
                context.buyAgain(row.mallSaleOrderId);
              }}
            >
              再次购买
            </a>
          </div>
        );
      },
    },
  ];
};

// 每一项的标题头
export const ITEM_HEADER_COLUMNS = (context: Store): EachHeaderProps[] => {
  return [
    {
      key: 'mallReturnOrderNo',
      rowFormatter: (row) => {
        return (
          <>
            <div>
              订单编号：
              {row.mallSaleOrderNo}
            </div>
            <CopyIcon text={row.mallSaleOrderNo}/>
          </>
        );
      },
    },
    {
      key: 'createdTime',
      rowFormatter: (row) => {
        return (
          <div>
            下单时间：
            {row.createdTime}
          </div>
        );
      },
    },
    {
      key: 'platformOrderNo',
      rowFormatter: (row) => {
        return (
          <div>
            {
              row.platformOrderNo ? (
                <div className={styles.platformOrderNoWrapper}>
                  <div className={styles.relatedOrderNo}>
                    关联店铺单号：
                    <span onClick={() => {
                      window.top.egenie.openTab(`/egenie-ts-vogue/shopOrder/index?platformOrderCode=${row.platformOrderNo}`, 752, '店铺订单');
                    }}
                    >
                      {row.platformOrderNo}
                      {' '}

                      {'>'}
                    </span>
                    <CopyIcon text={row.platformOrderNo}/>
                  </div>
                </div>
              ) : null
            }
          </div>

        );
      },
    },
    {
      key: 'orderType',
      rowFormatter: (row) => {
        return (
          <div className={styles.flex}>
            <div className={`${styles.orderStatus} ${row.orderType == 2 && styles.yellowStatus}`}>
              {orderTypeDict[row.orderType]}
            </div>
            {row.mallReturnRightsId && (
              <div
                className={styles.retunGoodsWithoutWorry}
                onClick={() => {
                  context.returnGoodsStore?.onDrawerShow(row.mallSaleOrderId, row.orderType);
                }}
              >
                无忧退货
                {' '}
                {'>'}
              </div>
            )}
            {
              row.doesBatchOrder ? (
                <div className={styles.batchTag}>
                  批
                </div>
              ) : null
            }
          </div>
        );
      },
    },
  ];
};

// 订单类型字典
const orderTypeDict = {
  '0': '全部',
  '1': '手工下单',
  '2': '代发订单',
  '3': '衫数样衣订单',
  '4': '第三方来源订单',
  '5': '导入订单',
  '8': '国际订单',
};

