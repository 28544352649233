import { Button, Card, Space } from 'antd';
import { ExportModal, ImportModal, NormalProgrammeComponent, Permission } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import ApplyAfterSales from '../applyAfterSales';
import { OrderGrid, TopTab } from '../components';
import { CourierInfoDrawer } from './courierInfo';
import styles from './index.less';
import ReturnGoods from './returnGoods';
import { PayByBatchModal } from './payByBatchNo';
import { Store } from './store';
import { CancelByBatchModal } from './cancelByBatchNo';

const store = new Store();

const MyOrder: React.FC = observer(() => {
  const { activeStatus, orderStatusList, programme, handleTabChange, orderGridStore,
    returnGoodsStore, courierInfoStore, applyAfterSaleStore, exportStore,
    importModel, openImportModal, openImportOverseasModal, payByBatchStore, cancelByBatchStore } = store;
  return (
    <div className={styles.page}>
      <TopTab
        activeKey={activeStatus}
        list={orderStatusList}
        onTabChange={handleTabChange}
      />
      <Card className={styles.card}>
        <NormalProgrammeComponent store={programme}/>
      </Card>
      <div className={styles.buttonWrapper}>
        <Space>
          <Permission permissionId="753_4075">
            <Button
              onClick={store.exportOrder}
              size="small"
            >
              批量导出
            </Button>
          </Permission>
          <Permission permissionId="753_5053">
            <Button
              onClick={openImportModal}
              size="small"
            >
              订单导入
            </Button>
          </Permission>
          <Permission permissionId="753_5057">
            <Button
              onClick={openImportOverseasModal}
              size="small"
            >
              国际订单导入
            </Button>
          </Permission>

          <Permission permissionId="753_5054">
            <Button
              onClick={store.batchPay}
              size="small"
            >
              批量付款
            </Button>
          </Permission>

          <Permission permissionId="753_5052">
            <Button
              onClick={store.payByBatchNo}
              size="small"
            >
              批量付款(按下单批次)
            </Button>
          </Permission>

          <Permission permissionId="753_5055">
            <Button
              onClick={store.batchCancel}
              size="small"
            >
              批量取消
            </Button>
          </Permission>
          <Permission permissionId="753_5051">
            <Button
              onClick={store.cancelByBatchNo}
              size="small"
            >
              批量取消(按下单批次)
            </Button>
          </Permission>
        </Space>
        <Button
          onClick={() => {
            store.applyAfterSaleStore.changeVisible(true);
          }}
          size="small"
        >
          批量申请售后
        </Button>
      </div>
      <div className={styles.orderWrapper}>
        <OrderGrid store={orderGridStore}/>
      </div>
      {/* 无忧退货 */}
      <ReturnGoods store={returnGoodsStore}/>
      {/* 物流信息 */}
      <CourierInfoDrawer store={courierInfoStore}/>
      {/* 申请售后 */}
      <ApplyAfterSales store={applyAfterSaleStore}/>
      {/* 导出 */}
      <ExportModal store={exportStore}/>
      <ImportModal store={importModel}/>
      {/* 批量付款（按批次） */}
      <PayByBatchModal store={payByBatchStore}/>
      {/* 批量取消（按下单批次） */}
      <CancelByBatchModal store={cancelByBatchStore}/>
    </div>
  );
});

export default MyOrder;
