import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';

const cashierQueryResult = (onOk: () => void, onCancel?: () => void): void => {
  Modal.confirm({
    title: '请您在新打开的页面上完成付款',
    icon: <ExclamationCircleOutlined/>,
    content: (
      <>
        <div>
          付款完成前请不要关闭窗口
        </div>
        <div>
          完成付款后请根据您的情况点击下面的按钮
        </div>
      </>),
    okText: '已完成付款',
    cancelText: '付款遇到问题',
    onOk() {
      onOk();
    },
    onCancel() {
      onCancel && onCancel();
    },
  });
};
export { cashierQueryResult };
