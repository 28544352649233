import { action, observable } from 'mobx';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { message, Modal } from 'antd';
import type { WaitingPaybatchData } from '../interface';

export class CancelByBatchStore {
  constructor(options) {
    this.parent = options.parent;
  }

  public parent;

  @observable public loading = false;

  @observable public visible = false;

  @observable public waitForPayBatchData = [];

  @action public togglePayBatchModal = (visible: boolean) => {
    if (visible) {
      this.loading = true;
      this.getWaitForBatchData();
    }
    this.visible = visible;
  };

  // 刷新付款单批次列表
  public getWaitForBatchData = () => {
    this.loading = true;
    return request<BaseData<WaitingPaybatchData[]>>({ url: '/api/mall/rest/saleOrder/queryWaitingPayBatchData' }).then((res) => {
      this.waitForPayBatchData = res.data;
    })
      .finally(() => {
        this.loading = false;
      });
  };

  // 处理付款
  public handleEachCancel = (outBatchNo) => {
    Modal.confirm({
      title: '提示',
      content: '是否确认取消该批次订单？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        this.loading = true;
        return request<BaseData>({
          url: '/api/mall/rest/saleOrder/batch/cancel/orderByOutBatchNos',
          method: 'POST',
          data: { ids: outBatchNo },
        }).then((res) => {
          message.success('该批次订单取消成功');
        })
          .finally(() => {
            this.loading = false;
            this.getWaitForBatchData();
          });
      },
    });
  };
}
