export const steps = [
  {
    title: '选择售后服务类型',
    status: 'one',
  },
  {
    title: '选择售后商品',
    status: 'two',
  },
  {
    title: '完善售后信息',
    status: 'three',
  },
];
