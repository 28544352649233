import { CloseCircleFilled } from '@ant-design/icons';
import { Form, Radio, Space, Select, Input, Upload, Button, message } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import type Store from '../../store';
import styles from './index.less';

const { Item } = Form;

const formLayout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 12 },
};
export const StepThree: React.FC<{ store: Store; }> = observer((props) => {
  const { stepGo,
    reasonOptions,
    returnAmount,
    beforeUpload,
    deleteImageUrl,
    uploadDisable,
    uploadedList,
    validatePicNum,
    setRef,
    confirmLoading,
    changeReason,
    reasonCode,
    deliverType,
    submit } = props.store;
  return (
    <div className={styles.submitForm}>
      <Form
        ref={setRef}
        {...formLayout}
        initialValues={{ type: 1 }}
        layout="horizontal"
      >
        <Item label="预估退款金额">
          <div className={styles.amount}>
            {`¥${returnAmount}`}
          </div>
        </Item>
        <Item
          label="售后类型"
          name="type"
          required
          rules={[
            {
              required: true,
              message: '请选择售后类型',
            },
          ]}
        >
          <Radio.Group className={styles.radio}>
            <Space
              direction="vertical"
              size={16}
            >
              <Radio value={1}>
                仅退款(无需退货)
                <div className={styles.radioDesc}>
                  没收到货，或与平台协商同意无需退货只退款
                </div>
              </Radio>
              {
                deliverType === 'delivered' && (
                  <Radio value={2}>
                    退货退款
                    <div className={styles.radioDesc}>
                      已收到货，需要退还收到的货物
                    </div>
                  </Radio>
                )
              }

            </Space>
          </Radio.Group>
        </Item>
        <Item
          label="售后原因"
          name="reason"
          required
          rules={[
            {
              required: true,
              message: '请选择售后原因',
            },
          ]}
        >
          <Select
            onChange={changeReason}
            options={reasonOptions}
            placeholder="请选择"
            style={{ width: 211 }}
          />
        </Item>
        <Item
          label="申请说明"
          name="illustration"
        >
          <Input.TextArea
            autoSize={{ minRows: 4 }}
            maxLength={140}
            placeholder="请输入申请退款的详细说明"
            showCount
          />
        </Item>
        <Item
          label="上传凭证"
          name="voucher"
          required={reasonCode !== 9}
          rules={[{ validator: validatePicNum }]}
        >
          <div className={styles.uploadItemWrap}>
            <span className={styles.tip}>
              请务必拍全所有需要退货的商品，确认原吊牌、外包装袋无遗失
            </span>
            <div className={styles.uploadContainer}>

              <Upload
                beforeUpload={beforeUpload}
                showUploadList={false}
              >
                <div
                  className={uploadDisable ? `${styles.disable} ${styles.uploadIcon}` : `${styles.uploadIcon}`}
                  onClick={(e) => {
                    if (uploadDisable) {
                      message.warning('最多上传5张凭证');
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                >
                  <span>
                    +
                  </span>
                  <div>
                    上传图片
                  </div>
                </div>
              </Upload>
              {uploadedList?.map((el) => {
                return (
                  <Image
                    imageUrl={el.imageUrl}
                    key={nanoid(5)}
                    onClickClose={deleteImageUrl}
                    progress={el.progress}
                    status={el.status}
                  />
                );
              })}

            </div>
          </div>

        </Item>
      </Form>
      <div className={styles.btnLine}>
        <Space>
          <Button
            loading={confirmLoading}
            onClick={submit}
            type="primary"
          >
            提交
          </Button>
          <Button onClick={() => stepGo(-1)}>
            上一步
          </Button>
        </Space>
      </div>
    </div>
  );
});

const Image: React.FC<{
  imageUrl: string;
  status: 'loading' | 'finish';
  progress: number;
  onClickClose: (imageUrl: string) => void;
}> = (props) => {
  const { imageUrl, onClickClose, status, progress } = props;
  if (status === 'loading') {
    return (
      <div className={styles.imageProgress}>
        <progress
          max={1}
          value={progress}
        />
      </div>
    );
  }
  return (
    <div className={styles.image}>
      <img src={imageUrl}/>
      <CloseCircleFilled
        className={styles.close}
        onClick={() => {
          onClickClose(imageUrl);
        }}
      />
    </div>
  );
};

