import { message, Modal } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { ExportStore, ImportModel, NormalProgramme, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import ApplyAfterSaleStore from '../applyAfterSales/store';
import { cashierQueryResult } from '../cashier/widget/cashierQueryResult/index';
import { OrderGridStore } from '../components/orderGrid/store';
import { api } from './api';
import { FILTER_ITEMS, GRID_COLUMNS, ITEM_HEADER_COLUMNS } from './constant';
import { CourierInfoStore } from './courierInfo/store';
import type { IBuyAgainVo, IOrderStatus, IUser } from './interface';
import ReturnGoodsStore from './returnGoods/store';
import { PayByBatchStore } from './payByBatchNo/store';
import { CancelByBatchStore } from './cancelByBatchNo/store';

export class Store {
  constructor() {
    this._init();
    this.getFilterOptions();
    this.queryOrder();
    this.getUserInfo();
  }

  public returnGoodsStore = new ReturnGoodsStore(this);// 无忧退货store

  public courierInfoStore = new CourierInfoStore(this);// 物流信息store

  public applyAfterSaleStore = new ApplyAfterSaleStore({ parent: this });

  public payByBatchStore = new PayByBatchStore({ parent: this });// 批量付款（按批次）

  public cancelByBatchStore = new CancelByBatchStore({ parent: this });// 批量取消（按批次）

  public exportStore = new ExportStore({ parent: this });

  public importModel = new ImportModel();

  @observable public activeStatus = -1;// 选中订单状态

  @observable public orderStatusList = [];// 订单状态列表

  @observable public associatedShopId = 0;

  @action private _init = (): void => {
    const params = location.href.split('?')[1];
    const search = new URLSearchParams(params);
  };

  @action
  public getUserInfo = async() => {
    const req = await request<{ associatedShopId: number; }>({
      method: 'GET',
      url: '/api/dashboard/user',
    });
    this.associatedShopId = req.associatedShopId;
  };

  // 查询订单列表
  public queryOrder = () => {
    const params = this.programme.filterItems.params;

    // 只要选了订单状态就要把顶上的tab选中
    this.activeStatus = params.orderStatus ? Number(params.orderStatus) : -1;
    const pageInfo = this.orderGridStore.pageInfo;

    // 处理创建时间
    if (params.time) {
      const time = {
        startTime: (params.time as string).split(',')[0],
        endTime: (params.time as string).split(',')[1],
      };
      Object.assign(params, time);
      delete params.time;
    }

    // 增加分页信息
    Object.assign(params, {
      page: pageInfo.page,
      pageSize: pageInfo.pageSize,
      sidx: 'createdAt',
      sord: 'desc',
    });

    this.orderGridStore.loading = true;
    return request<PaginationData>({
      url: api.queryOrderList,
      method: 'POST',
      data: params,
    })
      .then((res) => {
        this.orderGridStore.list = res.data.list;
        this.orderGridStore.pageInfo = {
          page: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totalCount,
        };
      })
      .finally(() => {
        this.orderGridStore.loading = false;
      });
  };

  // 页码变化
  public onPageChange = (page: number, pageSize: number) => {
    this.orderGridStore.pageInfo = {
      ...this.orderGridStore.pageInfo,
      page,
      pageSize,
    };
    this.getOrderStatusList();
    return this.queryOrder();
  };

  // 导出
  @action public exportOrder = (): void => {
    const selectedIds = this.orderGridStore.selectedIds;
    const params = this.programme.filterItems.params;
    const queryParamShow = this.programme.filterItems.translateParams.join(' ');
    if (params.time) {
      const time = {
        startTime: (params.time as string).split(',')[0],
        endTime: (params.time as string).split(',')[1],
      };
      Object.assign(params, time);
      delete params.time;
    }
    const fileName = '选款订单导出'; // `选款订单导出_${moment(Date.now()).format('YYYY-MM-DD hh:mm:ss')}`;
    if (selectedIds.length === 0) {
      Modal.confirm({
        title: '提示',
        content: '未选择数据将导出全部数据?',
        onOk: () => {
          this.exportStore.onShow(fileName, 'mall_purchase_order', '', {}, queryParamShow.trim(), params); // ids不传即代表导出全部数据
        },
      });
      return;
    }

    this.exportStore.onShow(fileName, 'mall_purchase_order', selectedIds.join(','), {}, queryParamShow.trim(), params); // 勾选部分数据
  };

  // 查询方案
  public programme = new NormalProgramme({
    filterItems: FILTER_ITEMS(this),
    count: 6,
    handleSearch: () => {
      const { pageSize } = this.orderGridStore.pageInfo;
      return this.onPageChange(1, pageSize);
    },
  });

  // 处理顶部tab选项变化
  @action public handleTabChange = (key: number) => {
    this.activeStatus = key;
    this.programme.filterItems.updateFilterItem([
      {
        field: 'orderStatus',
        value: key === -1 ? '' : key.toString(),
      },
    ]);

    // 重置表格组件页码
    this.orderGridStore.pageInfo.page = 1;
    this.programme.handleSearch();
  };

  // 获取查询项下拉框选项
  @action public getFilterOptions = () => {
    this.getOrderStatusList();

    // 获取下单账号
    request<BaseData<IUser[]>>({ url: api.getUsers })
      .then((res) => {
        this.programme.filterItems.addDict({ userId: this.mapOptions(res.data || [], 'id', 'showName') });
      });
  };

  // 获取订单状态
  private getOrderStatusList = () => {
    request<BaseData<IOrderStatus[]>>({ url: api.queryStatusList })
      .then((res) => {
        const orderStatusList = this.mapOptions(res.data, 'code', 'name');
        this.programme.filterItems.addDict({ orderStatus: orderStatusList });
        this.orderStatusList = res.data.map((item) => ({
          label: item.name,
          value: item.code,
          number: item.number,
        }));
      });
  };

  // 打开售后详情
  public goToAfterSaleDetail = (orderNo: string) => {
    window.top.egenie.openTab(`/egenie-ts-vogue/afterSaleOrder/index?saleOrderNo=${orderNo}`, 754, '售后管理');
  };

  // 取消订单
  public cancelOrder = (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确定取消该订单吗？',
      onOk: () => {
        return request<BaseData<string>>({
          url: api.cancelOrder,
          method: 'GET',
          params: { id },
        })
          .then((res) => {
            message.success(res.data || '操作成功');
            this.getOrderStatusList();
            this.queryOrder();
          });
      },
    });
  };

  // 确认收货
  public confirmReceiveGoods = (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确定确认收货吗？',
      onOk: () => {
        return request<BaseData>({
          url: api.confirmReceive,
          method: 'GET',
          params: { id },
        })
          .then((res) => {
            message.success(res.data || '操作成功');
            this.getOrderStatusList();
            this.queryOrder();
          });
      },
    });
  };

  // 处理选项
  private mapOptions = (data, key: string, val: string) => {
    return data?.map((item) => ({
      label: item[val],
      value: item[key],
    })) || [];
  };

  public openImportModal = () => {
    this.importModel.openModal({ sheetName: 'mall_sale_order' });
  };

  public openImportOverseasModal = () => {
    this.importModel.openModal({ sheetName: 'mall_sale_order_overseas' });
  };

  // 批量付款
  public batchPay = (): void => {
    const selectedRows = this.orderGridStore.selectedRows;

    if (!selectedRows.length || selectedRows.some((item) => item.orderStatus !== 0)) {
      message.warn('请勾选待付款的订单');
      return;
    }

    // 已经按下单批次付款的待付款单不可选
    if (selectedRows.some(((item) => item.doesBatchOrder))) {
      message.warn('请不要勾选按批次付款的订单');
      return;
    }

    const tradeOrderIds = [];
    selectedRows.forEach((row) => {
      if (row.courierOrderNo) {
        tradeOrderIds.push(row.courierOrderNo);
      }
      tradeOrderIds.push(row.businessId);
    });
    top.egenie.openTab(`/egenie-ts-vogue/cashier/index?tradeOrderIds=${JSON.stringify(tradeOrderIds)}`, 'cashier', '收银台');
    cashierQueryResult(this.queryOrder, this.queryOrder);
  };

  // 按批次付款
  public payByBatchNo = () => {
    this.payByBatchStore.togglePayBatchModal(true);
  };

  // 批量取消
  public batchCancel = () => {
    const selectedRows = this.orderGridStore.selectedRows;
    const mallSaleOrderIds = selectedRows.map((v) => v.mallSaleOrderId);
    if (!selectedRows.length || selectedRows.some((item) => item.orderStatus !== 0)) {
      message.warn('请勾选待付款的订单');
      return;
    }
    Modal.confirm({
      title: '',
      content: '是否确认取消当前已选中的订单？',
      onOk: () => {
        return request<BaseData<string>>({
          url: api.batchCancelOrder,
          method: 'POST',
          data: { mallSaleOrderIds },
        })
          .then((res) => {
            message.success(res.data || '操作成功');
            this.getOrderStatusList();
            this.queryOrder();
          });
      },
    });
  };

  // 批量取消（按下单批次）
  public cancelByBatchNo = () => {
    this.cancelByBatchStore.togglePayBatchModal(true);
  };

  // 快递停发提示
  public expressDeliveryPop = () => {
    Modal.warn({
      title: '提示',
      content: '因快递停发暂时无法发货，我们正在积极协调快递公司，如果您不能继续等待也可以直接申请售后。',
    });
  };

  // 再次购买
  public buyAgain = (orderId: number) => {
    request<BaseData<IBuyAgainVo>>({
      url: api.buyAgain,
      method: 'POST',
      data: { ids: orderId.toString() },
    })
      .then((res) => {
        const doesAllGoods = res.data.doesAllGoods;
        const addShoppingCartIds = res.data.addShoppingCartIds;
        if (!doesAllGoods) { // 有款式被下架
          const content = addShoppingCartIds.length === 0 ? '订单中的所有商品已下架' : '订单中的部分商品已下架或库存不足，系统将自动跳过';
          Modal.warn({
            title: '提示',
            content,
            onOk: () => {
              if (addShoppingCartIds.length !== 0) {
                if (this.associatedShopId) {
                  window.open(`/egenie-ts-vogue/distributor/shoppingCart/index?addShoppingCartIds=${addShoppingCartIds.join(',')}`);
                } else {
                  window.open(`/egenie-ts-vogue/shoppingCart/index?addShoppingCartIds=${addShoppingCartIds.join(',')}`);
                }
              }
            },
          });
        } else {
          if (this.associatedShopId) {
            window.open(`/egenie-ts-vogue/distributor/shoppingCart/index?addShoppingCartIds=${addShoppingCartIds.join(',')}`);
          } else {
            window.open(`/egenie-ts-vogue/shoppingCart/index?addShoppingCartIds=${addShoppingCartIds.join(',')}`);
          }
        }
      });
  };

  public orderGridStore = new OrderGridStore({
    list: [],
    primaryKey: 'mallSaleOrderId',
    detailField: 'detailVos',
    detailHeight: 100,
    detailKey: 'skuNo',
    itemHeaderColumns: ITEM_HEADER_COLUMNS(this),
    gridColumns: GRID_COLUMNS(this),
    onPageChange: this.onPageChange,
    showRefresh: true,
  });
}
